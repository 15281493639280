import { useSpring, animated } from '@react-spring/web'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ImageCover } from '/features/buildingBlocks/Image.js'
import { InfoBarDark } from '/features/buildingBlocks/InfoBar'
import { HeadingSlantedLarge } from '/features/buildingBlocks/Heading'
import placeholder from '/images/placeholder-frame-job-hero.svg'
import placeholderMobile from '/images/placeholder-frame-job-hero-mobile.svg'
import mediaStyles from '/cssGlobal/media.css'
import { easeInOutQuart } from '/machinery/motion'

import styles from './Hero.css'

export function Hero({ image = undefined, title, location, employment, department }) {
  const viewportIsMd = useMediaQuery(mediaStyles.viewportMd)
  const animatedClipPathLeft = useSpring({
    from: {
      '--animated-clipping-point-top-left-x': '0%',
      '--animated-clipping-point-top-left-y': '100%',
      '--animated-clipping-point-top-right-x': '43%',
      '--animated-clipping-point-top-right-y': '100%'
    },
    to: {
      '--animated-clipping-point-top-left-x': '43%',
      '--animated-clipping-point-top-left-y': '0%',
      '--animated-clipping-point-top-right-x': '83%',
      '--animated-clipping-point-top-right-y': '0%'
    },
    config: {
      easing: easeInOutQuart,
      duration: 500
    }
  })

  const animatedClipPathRight = useSpring({
    from: {
      '--animated-clipping-point-bottom-left-x': '48%',
      '--animated-clipping-point-bottom-left-y': '100%',
      '--animated-clipping-point-bottom-right-x': '89%',
      '--animated-clipping-point-bottom-right-y': '100%'
    },
    to: {
      '--animated-clipping-point-bottom-left-x': '58%',
      '--animated-clipping-point-bottom-left-y': '73%',
      '--animated-clipping-point-bottom-right-x': '100%',
      '--animated-clipping-point-bottom-right-y': '73%'
    },
    config: {
      easing: easeInOutQuart,
      duration: 500
    },
    delay: 200
  })

  const animationClipPaths = { ...animatedClipPathLeft, ...animatedClipPathRight }

  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <div className={styles.layout}>
          <div className={styles.contentBox}>
            <div className={styles.titleBox}>
              <HeadingSlantedLarge h={1} layoutClassName={styles.title} {...{ title }} />
            </div>
            {viewportIsMd && <InfoBarDark {...{ location, employment, department }} />}
          </div>

          <div className={styles.visual}>
            {image?.asset ?
              <animated.div style={animationClipPaths} className={styles.clippingAnimation}>
                <ImageCover
                  aspectRatio={4 / 3}
                  {...{ image }}
                  layoutClassName={styles.clippingMask}
                  sizes='min(768px, calc(100vw - 40px))'
                />
              </animated.div>
              : <img src={viewportIsMd ? placeholder : placeholderMobile} className={styles.placeholderFrame} alt='' />
            }
          </div>
        </div>

        <div className={styles.mobileInfoBarWrapper}>
          {viewportIsMd === false && <InfoBarDark {...{ location, employment, department }} layoutClassName={styles.infoBar} />}
        </div>
      </div>
    </div>
  )
}
