import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Chip.css'

import departmentIcon from '/images/chips/department.raw.svg'
import employmentIcon from '/images/chips/employment.raw.svg'
import locationIcon from '/images/chips/location.raw.svg'

export function ChipDepartment({ department }) {
  return (
    <ChipBase icon={departmentIcon}>
      {department}
    </ChipBase>
  )
}

export function ChipEmployment({ employment }) {
  return (
    <ChipBase icon={employmentIcon}>
      {employment}
    </ChipBase>
  )
}

export function ChipLocation({ location }) {
  return (
    <ChipBase icon={locationIcon} >
      {location}
    </ChipBase>
  )
}

export function ChipDepartmentDark({ department }) {
  return (
    <ChipBase icon={departmentIcon} dark>
      {department}
    </ChipBase>
  )
}

export function ChipEmploymentDark({ employment }) {
  return (
    <ChipBase icon={employmentIcon} dark>
      {employment}
    </ChipBase>
  )
}

export function ChipLocationDark({ location }) {
  return (
    <ChipBase icon={locationIcon} dark>
      {location}
    </ChipBase>
  )
}

function ChipBase({ children, icon, dark = undefined }) {
  return (
    <div className={cx(styles.componentBase, dark && styles.isDark)}>
      <div className={cx(styles.icon, dark && styles.isDark)}>
        <Icon {...{ icon }} />
      </div>
      {children}
    </div>
  )
}
