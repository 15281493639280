import { ChipLocationDark, ChipDepartmentDark, ChipEmploymentDark, ChipLocation, ChipDepartment, ChipEmployment } from '/features/buildingBlocks/Chip'

import styles from './InfoBar.css'

export function InfoBarDark({ location, employment, department, layoutClassName = undefined }) {
  return (
    <InfoBarBase
      {...{ layoutClassName }}
      location={location && <ChipLocationDark {...{ location }} />}
      department={department && <ChipDepartmentDark {...{ department }} />}
      employment={employment && <ChipEmploymentDark {...{ employment }} />}
    />
  )
}

export function InfoBar({ location, employment, department, layoutClassName = undefined }) {
  return (
    <InfoBarBase
      {...{ layoutClassName }}
      location={location && <ChipLocation {...{ location }} />}
      department={department && <ChipDepartment {...{ department }} />}
      employment={employment && <ChipEmployment {...{ employment }} />}
    />
  )
}

function InfoBarBase({ location, employment, department, layoutClassName }) {
  return (
    <div className={cx(styles.componentBase, layoutClassName)}>
      <ul className={styles.infoList}>
        {location && (
          <li>
            {location}
          </li>
        )}
        {department && (
          <li>
            {department}
          </li>
        )}
        {employment && (
          <li>
            {employment}
          </li>
        )}
      </ul>
    </div>
  )
}
